import { Message } from "@sybel-maker/core/src/dtos/Message";
import axios from "axios";
import { last } from "radash";
import { chatGPT } from "./chatGPT.service";

const apiUrl =
    process.env.API_URL ||
    "https://dev-maker-api.sybel.co"; // Local for when running locally
const openaiKey =
    process.env.OPENAI_KEY ||
    "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"; // Local for when running locally

export class ChatService {
    public async create(name: string, engine: string, configInstruction: string | undefined) {
        const r = await axios.post(`${apiUrl}/chat`, { name, engine, configInstruction });
        return r.data;
    }

    public async list() {
        const chats = await axios.get(`${apiUrl}/chat`);
        return chats.data;
    }

    public async sendMessage(
        chatId: string,
        messages: Message[],
        engine: string,
    ): Promise<string | undefined> {
        const provider = this.getProviderFromEngine(engine);
        const response =
            provider === "openai"
                ? await this.askCG(messages, engine, chatId)
                : await this.askVertex(messages, engine, chatId);
        return response;
    }

    /**
     * 
     * This goes front to API, so we have to send results to the backend ourselves
     */
    private async askCG(messages: Message[], engine: string, chatId: string): Promise<string> {
        const chatGTPService = new chatGPT(openaiKey);
        // Manage back and forth
        const completion = await chatGTPService
            .ask(messages, engine)
            .then((r) => {
                console.log(r);
                return r;
            })
            .catch((e) => console.error(e));
        if (!completion || !last(completion.choices)?.message.content)
            throw new Error("No completion");
        const response = last(completion.choices)?.message.content || "";

        if (response) {
            // If everything goes well, let's send both messages to backend
            // a. user message
            await axios({
                method: "post",
                url: `${apiUrl}/message`,
                data: {
                    chatId,
                    message: last(messages),
                    engine,
                },
            });
            // b. AI response
            await axios({
                method: "post",
                url: `${apiUrl}/message`,
                data: {
                    chatId,
                    message: {
                        content: response,
                        role: "assistant",
                        engine,
                    },
                    engine,
                },
            });
            return response;
        }
        throw new Error("No response from AI");
    }

    /**
     * Vertex is called by backend, so we don't have to send results to the backend ourselves, it stores it in the db.
     */
    private async askVertex(
        messages: Message[],
        engine: string,
        chatId: string
    ): Promise<string> {
        const fromBackend = await axios.post(`${apiUrl}/ask`, {
            engine,
            messages,
            chatId
        });
        return fromBackend.data
    }

    private getProviderFromEngine(engine: string) {
        const engineFound = this.engines.find((e) => e.engine === engine);
        if (!engineFound) throw new Error("Engine not found");
        return engineFound.provider;
    }

    public delete = async (chatId: string) =>
        axios.delete(`${apiUrl}/chat?chatId=${chatId}`);

    public engines = [
        { engine: "gpt-4", provider: "openai", fineTuning: false },
        { engine: "gpt-3.5-turbo-0613", provider: "openai", fineTuning: false },
        { engine: "text-bison", provider: "vertex", fineTuning: false },
        { engine: "chat-bison", provider: "vertex", fineTuning: false },
        {
            engine: "ft:gpt-3.5-turbo-0613:personal::8CT4GYOn",
            provider: "openai",
            fineTuning: true,
        },
        {
            engine: "ft:gpt-3.5-turbo-0613:personal::8DrujoVi",
            provider: "openai",
            fineTuning: true,
        },
    ];
}
