import { Message } from "@sybel-maker/core/src/dtos/Message";
import OpenAI from "openai";
import { last, pick, sum } from "radash";

export class chatGPT {
    constructor(private readonly key: string | undefined) { }

    private readonly openai = new OpenAI({
        apiKey: this.key,
        dangerouslyAllowBrowser: true,
    });

    public ask = async (allMessages: Message[], model: string) =>
        this.openai.chat.completions.create({
            model,
            messages: allMessages.map((m) => pick(m, ["content", "role"])),
            // max_tokens: 2000,
            // max_tokens: 4000 - sum(allMessages.map(m => m.content?.length || 0)),
            // temperature: 0.9,
            // top_p: 1,

            // stream: true,
        });
}
