import { Message } from "@sybel-maker/core/src/dtos/Message";
import { useState } from "react";

export const Chat = (props: {
    input: string;
    setInput: any;
    messages: Message[];
    loading: boolean;
    sendMessage: any;
    sendContinue: any;
    activeEngine: Message["engine"] | null;
}) => {
    const { input, setInput, messages, loading, sendMessage, sendContinue, activeEngine } =
        props;

    const send = async () => {
        if (input) {
            sendMessage(input, activeEngine);
        }
    }

    return (
        <>
            <div id="messages">
                {messages.length ? (
                    messages.map((message, i) => (
                        <div
                            key={i}
                            className={`message ${message.role.toString()}`}
                        >
                            <p
                                style={{
                                    whiteSpace: "pre-line",
                                }}
                            >
                                {message.content}
                            </p>
                        </div>
                    ))
                ) : (
                    <p>
                        <i>C'est le début.</i>
                    </p>
                )}
            </div>
            <div id="input">
                <form>
                    <h3>Chat</h3>
                    <textarea
                        value={input}
                        onChange={(e) => setInput(e.currentTarget.value)}
                    />
                    <br />
                    <input
                        type="button"
                        value={
                            loading
                                ? "Je demande à ChatGPT..."
                                : "Demander à " + activeEngine
                        }
                        disabled={loading}
                        onClick={send}
                    />
                    <input
                        type="button"
                        value={"Continue"}
                        disabled={loading || messages.length < 2}
                        onClick={sendContinue}
                    />
                </form>
            </div>
        </>
    );
};
