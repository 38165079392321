import { useEffect, useState } from "react";
import "./App.css";
import { ChatService } from "./services/chat.service";
import { ChatLoaded } from "@sybel-maker/core/src/dtos/Chat";
import { get, last } from "radash";
import { Message } from "@sybel-maker/core/src/dtos/Message";
import { NewChatWindow } from "./components/NewChatWindow";
import { Chat } from "./components/Chat";

function App() {
    const chatService = new ChatService();

    const firstInputDefaultValue =
        "Ecris le résumé des épisodes d'une série audio comportant les sujets suivants : la famille, les animaux, la nature, de l'aventure.";
    const [me, setMe] = useState<string>("Helo");
    const [chats, setChats] = useState<ChatLoaded[]>([]);
    const [messages, setMessages] = useState<Message[]>([]);
    const [input, setInput] = useState<string>(firstInputDefaultValue);
    const [loading, setLoading] = useState<boolean>(false);
    const [threadId, setThreadId] = useState<string | null>(null);
    const [activeEngine, setActiveEngine] = useState<Message["engine"]>(
        chatService.engines[0].engine as Message["engine"],
    );
    const [newChatWindowOpen, setNewChatWindowOpen] = useState<boolean>(false);

    useEffect(() => {
        chatService.list().then(setChats);
    }, []);

    useEffect(() => {
        const messages = chats.find((chat) => chat._id.toString() === threadId)
            ?.messages;
        setMessages(messages || []);
        if (messages) {
            setActiveEngine(last(messages)?.engine || activeEngine);
        }
        setInput("");
    }, [threadId]);

    const chatCreate = async (
        name: string,
        chosenEngine: Message["engine"],
        configInstruction: string | undefined,
    ) => {
        if (name && name.length > 2) {
            try {
                await chatService.create(name, chosenEngine, configInstruction).then((chat) => {
                    if (chat) {
                        setThreadId(chat._id);
                        setChats([...chats, chat]);
                        setNewChatWindowOpen(false);
                        setActiveEngine(chosenEngine)
                    }
                });
            } catch (err) {
                if (get(err, "response.data.error"))
                    alert(get(err, "response.data.error"));
            }
        }
    };

    const sendMessage = async (content: string): Promise<void> => {
        if (threadId) {
            setLoading(true);
            const messagesUpdated = [
                ...messages,
                { content, role: "user" } as Message,
            ];
            try {
                await chatService
                    .sendMessage(threadId, messagesUpdated, activeEngine)
                    .then((message) => {
                        if (message) {
                            setMessages(messagesUpdated);
                            setInput("");
                            setMessages([
                                ...messagesUpdated,
                                {
                                    content: message,
                                    role: "assistant",
                                } as Message,
                            ]);
                        }
                    });
            } catch (err) {
                console.error(err);
                if (get(err, "response.data.error"))
                    alert(get(err, "response.data.error"));
                else alert(err)
            }
            setLoading(false);
        }
    };

    const sendContinue = async () => {
        sendMessage("Continue");
    };

    const deleteChat = async (id: string) => {
        if (window.confirm("Supprimer la conversation ?")) {
            await chatService.delete(id);
            if (id === threadId) setThreadId(null);
            chatService.list().then(setChats);
        }
    };

    return (
        <>
            <div className="App">
                <div className="sidebar">
                    <div>
                        <h3>Chats</h3>
                        {chats &&
                            chats.map((chat, i) => (
                                <div key={i} className="chatLine">
                                    <p
                                        onClick={() =>
                                            setThreadId(chat._id.toString())
                                        }
                                        className={
                                            "chat " +
                                            (threadId?.toString() ===
                                                chat._id.toString()
                                                ? "active"
                                                : "")
                                        }
                                    >
                                        {chat.name}
                                    </p>
                                    <button
                                        onClick={() =>
                                            deleteChat(chat._id.toString())
                                        }
                                    >
                                        X
                                    </button>
                                </div>
                            ))}
                        <div
                            className="chat"
                            style={{ color: "red" }}
                            onClick={() => setNewChatWindowOpen(true)}
                        >
                            <p>+ New</p>
                        </div>
                    </div>
                </div>
                <div id="main">
                    <div id="mainChat">
                        {newChatWindowOpen && (
                            <NewChatWindow
                                fallback={chatCreate}
                                setNewChatWindowOpen={setNewChatWindowOpen}
                            />
                        )}
                        {threadId && !newChatWindowOpen && (
                            <Chat
                                input={input}
                                setInput={setInput}
                                messages={messages}
                                loading={loading}
                                sendMessage={sendMessage}
                                sendContinue={sendContinue}
                                activeEngine={activeEngine}
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default App;
