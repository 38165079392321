import { useState } from "react";
import { ChatService } from "../services/chat.service";
import { Message } from "@sybel-maker/core/src/dtos/Message";

export const NewChatWindow = (props: any) => {
    const { fallback, setNewChatWindowOpen } = props;
    const [activeEngine, setActiveEngine] = useState<Message["engine"] | null>(
        null,
    );
    const [name, setName] = useState("");

    const [inputSystem, setInputSystem] = useState<string>(
        "Tu écris des scénarios pour des séries audio pour trois types de public : enfants, adolescents, adultes. Tu respectes les cinq règles basiques de narration :des personnages, un univers, un événement perturbateur, des péripéties, une résolution. Les séries sont constituées en général de quatre épisodes, chacun de 4000 caractères espaces compris. Chaque épisode (sauf le dernier) se termine par un cliffhanger pour attirer l'auditeur vers l'épisode suivant. Les histoires comprennent de 2 à 4 personnages, et l'histoire est contée par un narrateur. Le narrateur possède autant de temps de paroles que tous les personnages réunis. Des didascalies indiquent l'action se déroulant en arrière-plan, qui sera matérialisée par des effets sonores. Le hasard est proscrit, toutes les péripéties ainsi que les résolutions surviennent de façon logique, fluide, cohérente avec les personnages et mettant en jeu leur intelligence, leur courage, et / ou leur acuité."
    );

    const chatService = new ChatService();

    return (
        <div className="new-chat-window">
            <h3>Titre</h3>
            <input
                type="text"
                placeholder="Nom du chat"
                onChange={(e) => setName(e.currentTarget.value)}
            />
            <div>
                <h3>Assistant</h3>
                <select
                    defaultValue={activeEngine as string}
                    onChange={(e) => setActiveEngine(e.currentTarget.value as Message["engine"])}
                >
                    {chatService.engines.map((engine, i) => (
                        <option key={i} value={engine.engine}>
                            {engine.engine} ({engine.provider})
                        </option>
                    ))}
                </select>
            </div>
            <div className="systemInput">
                <h3>Message système (config de l'assistant)</h3>
                <textarea
                    value={inputSystem}
                    onChange={(e) => setInputSystem(e.currentTarget.value)}
                    style={{ width: "80%", height: 80 }}
                />
                <br />
            </div>
            <br />
            <br />
            <button onClick={() => fallback(name, activeEngine, inputSystem)}>Créer</button>
            <br />
            <br />
            <button onClick={() => setNewChatWindowOpen(false)}>Annuler</button>
        </div>
    );
};
